@font-face {
  font-family: 'Roobert';
  src: local('Roobert'), url('./assets/fonts/Roobert-Light.woff') format('woff');
  font-weight: 300;
}

@font-face {
  font-family: 'Roobert';
  src: local('Roobert'),
    url('./assets/fonts/Roobert-Regular.woff') format('woff');
  font-weight: 400;
}

@font-face {
  font-family: 'Roobert';
  src: local('Roobert'),
    url('./assets/fonts/Roobert-Medium.woff') format('woff');
  font-weight: 500;
}

@font-face {
  font-family: 'Roobert';
  src: local('Roobert'), url('./assets/fonts/Roobert-Bold.woff') format('woff');
  font-weight: 700;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
